
import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const get_HutangList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.HUTANG.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_HutangDetail = (id_hutang, callbackSuccess, callbackError) => {
  apiClient(true).get(API.HUTANG.GET, {id_hutang: id_hutang}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_HutangSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.HUTANG.SAVE, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_HutangSaveInvoice = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.HUTANG.SAVE_INVOICE, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_HutangSavePembayaran = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.HUTANG.SAVE_PEMBAYARAN, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_HutangPreInvoice = (id_hutang, callbackSuccess, callbackError) => {
  apiClient(true).get(API.HUTANG.PRE_INVOICE, {id_hutang: id_hutang}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_HutangInvoiceGet = (no_po, callbackSuccess, callbackError) => {
  apiClient(true).get(API.HUTANG.GET_INVOICE, {no_po: no_po}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_HutangInvoiceCancel = (no_po, callbackSuccess, callbackError) => {
  apiClient(true).get(API.HUTANG.CANCEL_INVOICE, {no_po: no_po}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_HutangJurnalBack = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.HUTANG.JURNAL_BACK, params).then(res => {
    if (res.status === 200 || res.status == 201) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_HutangListPO = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.HUTANG.LIST_PO, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PaymentOrderSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.HUTANG.PAYMENT_ORDER.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_PaymentOrderCetak = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.HUTANG.PAYMENT_ORDER.CETAK, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_PaymentOrderUpdateCetak = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.HUTANG.PAYMENT_ORDER.UPDATE_CETAK, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_HutangList,
  get_HutangDetail,
  post_HutangSave,
  post_HutangSaveInvoice,
  post_HutangSavePembayaran,
  get_HutangPreInvoice,
  get_HutangInvoiceGet,
  get_HutangInvoiceCancel,
  post_HutangJurnalBack,
  get_HutangListPO,
  post_PaymentOrderSave,
  get_PaymentOrderCetak,
  post_PaymentOrderUpdateCetak
}