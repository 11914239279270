import { API } from "../../constans/Url"
import { apiClient } from "../../helper"


const get_ProjectList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_ProjectSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ProjectDetail = (kode, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.GET, {kode: kode}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ProjectAnggota = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.LIST_ANGGOTA, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_ProjectCancel = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.CANCEL, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_ProjectDone = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.DONE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ProjectReportDownload = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.PROJECT_REPORT, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}


export {
  get_ProjectList,
  post_ProjectSave,
  get_ProjectDetail,
  get_ProjectAnggota,
  post_ProjectCancel,
  post_ProjectDone,
  get_ProjectReportDownload
}