import { API } from "../../constans/Url"
import { apiClient } from "../../helper"

const get_CompanyList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.COMPANY.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_CompanyDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.COMPANY.GET, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_CompanySave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.COMPANY.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_CompanyCheckCode = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.COMPANY.CHECK_KODE, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_CompanyRekeningList = (params, callbackSuccess, callbackError) => {
  apiClient(true).get(API.COMPANY.REKENING.LIST, params).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_CompanyList,
  get_CompanyDetail,
  post_CompanySave,
  get_CompanyCheckCode,
  get_CompanyRekeningList
}