import { API } from "../../../constans/Url"
import { apiClient } from "../../../helper"

const get_ProjectRabList = (kode, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.RAB.LIST, {kode_project: kode}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ProjectRabListParent = (kode, type = 'rab',  callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.RAB.LIST_PARENT, {kode_project: kode, type: type}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ProjectRabDetail = (id, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.RAB.GET, {id: id}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_ProjectRabSave = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.RAB.SAVE, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_ProjectRabImport = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.RAB.IMPORT, params, 
    {
      headers: {
          'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_ProjectRabAcc = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.RAB.ACC, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const post_ProjectRabGrouping = (params, callbackSuccess, callbackError) => {
  apiClient(true).post(API.PROJECT.RAB.GROUPING, params).then(res => {
    if (res.status === 201 || res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

const get_ProjectPeList = (kode, version, callbackSuccess, callbackError) => {
  apiClient(true).get(API.PROJECT.RAB.LIST, {kode_project: kode, type: 'pe', version: version}).then(res => {
    if (res.status === 200) {
      callbackSuccess(res.data)
    } else {
      callbackError(res.data)
    }
  }).catch(err => {
    callbackError(err)
  })
}

export {
  get_ProjectRabDetail,
  get_ProjectRabList, get_ProjectRabListParent, post_ProjectRabAcc,
  post_ProjectRabGrouping, post_ProjectRabImport, post_ProjectRabSave,
  get_ProjectPeList
}
