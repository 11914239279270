<template>
  <div
    class="modal fade"
    id="modalAddPayment"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Pembayaran Hutang</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label class="form-label">Akun</label>
              <Select2
                v-model="formPembayaran.id_akun"
                :options="optionKasBank"
                placeholder="Pilih akun perkiraan..."
                @change="formChangePembayaran('id_akun')"
                @select="formChangePembayaran('id_akun')"
                :class="{
                  'is-invalid':
                    formErrorPembayaran && formErrorPembayaran.id_akun,
                }"
                :settings="{
                  templateResult: formatState,
                }"
              />
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.id_akun"
              >
                {{ formErrorPembayaran.id_akun }}
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal Pembayaran :</label>
              <Datepicker
                :autoApply="true"
                :maxDate="new Date()"
                :class="{
                  'is-invalid':
                    formErrorPembayaran &&
                    formErrorPembayaran.tanggal_pembayaran,
                }"
                :closeOnScroll="true"
                v-model="formPembayaran.tanggal_pembayaran"
                placeholder="Tanggal Pembayaran"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="changeDatePembayaran('', 'tanggal_pembayaran')"
                @update:modelValue="
                  changeDatePembayaran($event, 'tanggal_pembayaran')
                "
              ></Datepicker>
              <!-- :minDate="formData.tanggal" -->
            </div>
            <div class="form-group">
              <label class="" for="inlineFormInputGroupUsername"
                >Nominal Pembayaran :</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-money="configMoney"
                  v-model.lazy="formPembayaran.nilai"
                  :class="{
                    'is-invalid':
                      formErrorPembayaran && formErrorPembayaran.nilai,
                  }"
                  @keyup="formChangePembayaran('nilai')"
                  class="form-control text-right"
                  placeholder="Nominal Pembayaran"
                />
              </div>
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.nilai"
              >
                {{ formErrorPembayaran.nilai }}
              </div>
            </div>
            <div class="form-group">
              <label>Nomor Referensi :</label>
              <input
                type="text"
                :class="{
                  'is-invalid':
                    formErrorPembayaran && formErrorPembayaran.no_referensi,
                }"
                @keyup="formChangePembayaran('no_referensi')"
                class="form-control"
                v-model="formPembayaran.no_referensi"
                placeholder="Masukkan Nomor Referensi"
              />
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.no_referensi"
              >
                {{ formErrorPembayaran.no_referensi }}
              </div>
            </div>
            <div class="form-group">
              <label>Keterangan</label>
              <textarea
                rows="3"
                placeholder="Masukan Keterangan"
                v-model="formPembayaran.keterangan"
                :class="{
                  'is-invalid':
                    formErrorPembayaran && formErrorPembayaran.keterangan,
                }"
                @keyup="formChangePembayaran('keterangan')"
                class="form-control"
              ></textarea>
              <div
                class="form-error"
                v-if="formErrorPembayaran && formErrorPembayaran.keterangan"
              >
                {{ formErrorPembayaran.keterangan }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="savePembayaran"
              :disabled="!isEditable || isPembayaran"
              class="btn btn-add"
            >
              <span class="fas fa-spinner fa-spin" v-if="isPembayaran"></span>
              Simpan Pembayaran
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="modalJurnalBack"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Jurnal Kembali</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group">
              <label>Nomor Pembayaran</label>
              <Select2
                v-model="formJurnalKembali.id_pembayaran"
                :options="optionPembayaran"
                placeholder="Pilih akun perkiraan..."
                @change="formChangeJurnal('id_pembayaran', $event)"
                @select="formChangeJurnal('id_pembayaran', $event)"
                :class="{
                  'is-invalid':
                    formErrorJurnal && formErrorJurnal.id_pembayaran,
                }"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.id_pembayaran"
              >
                {{ formErrorJurnal.id_pembayaran }}
              </div>
            </div>
            <div class="form-group">
              <label>Tanggal :</label>
              <Datepicker
                :autoApply="true"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.tanggal,
                }"
                :closeOnScroll="true"
                v-model="formJurnalKembali.tanggal"
                placeholder="Tanggal Pembayaran"
                :format="format"
                :enableTimePicker="false"
                locale="id-ID"
                selectText="Pilih"
                cancelText="Batal"
                @cleared="changeDateJurnal('', 'tanggal')"
                @update:modelValue="changeDateJurnal($event, 'tanggal')"
              >
              </Datepicker>
            </div>
            <div class="form-group">
              <label class="" for="inlineFormInputGroupUsername"
                >Nominal :</label
              >
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">Rp</div>
                </div>
                <input
                  type="text"
                  v-money="configMoney"
                  v-model.lazy="formJurnalKembali.nilai"
                  :class="{
                    'is-invalid': formErrorJurnal && formErrorJurnal.nilai,
                  }"
                  @keyup="formChangeJurnal('nilai')"
                  class="form-control text-right"
                  placeholder="Nominal Pembayaran"
                />
              </div>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.nilai"
              >
                {{ formErrorJurnal.nilai }}
              </div>
            </div>
            <div class="form-group">
              <label>Nomor Referensi :</label>
              <input
                type="text"
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.no_referensi,
                }"
                @keyup="formChangeJurnal('no_referensi')"
                class="form-control"
                v-model="formJurnalKembali.no_referensi"
                placeholder="Masukkan Nomor Referensi"
              />
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.no_referensi"
              >
                {{ formErrorJurnal.no_referensi }}
              </div>
            </div>
            <div class="form-group">
              <label>Keterangan :</label>
              <textarea
                :class="{
                  'is-invalid': formErrorJurnal && formErrorJurnal.keterangan,
                }"
                @keyup="formChangeJurnal('keterangan')"
                class="form-control"
                v-model="formJurnalKembali.keterangan"
                placeholder="Masukkan Keterangan"
              ></textarea>
              <div
                class="form-error"
                v-if="formErrorJurnal && formErrorJurnal.keterangan"
              >
                {{ formErrorJurnal.keterangan }}
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveJurnalBack"
              :disabled="
                isJurnalback ||
                formData.status_pembayaran == 'cancel' ||
                formData.status_pembayaran == 'menunggu persetujuan' ||
                formData.status_pembayaran == 'unpaid'
              "
              class="btn btn-add"
            >
              <span class="fas fa-spinner fa-spin" v-if="isJurnalback"></span>
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="modalPengeluaran"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="card-modal">
          <div class="modal-header">
            <h5 class="modal-title">Rincian Payment Order</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body mt-3">
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Akun Perkiraan </label>
              <Select2
                class="w-100"
                v-model="formModalPengeluaran.akun_beban"
                :options="OptionAccountEstimated"
                placeholder="Pilih akun perkiraan..."
                :settings="{
                  templateResult: formatState,
                }"
                :disabled="!isEditable"
                @change="formChangePengeluaran('akun_beban', $event)"
                @select="formChangePengeluaran('akun_beban', $event)"
                :class="{
                  'is-invalid': formErrorModal && formErrorModal.akun_beban,
                }"
              />
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Item</label>
              <div class="w-100">
                <Select2
                  v-model="formModalPengeluaran.item_rab"
                  :disabled="!isEditable"
                  :options="optionRab"
                  placeholder="Pilih Item..."
                  id="selectRab"
                  @change="formChangePengeluaran('item_rab', $event)"
                  @select="formChangePengeluaran('item_rab', $event)"
                  :settings="{ templateResult: formatStateRab }"
                  :class="{
                    'is-invalid': formErrorModal && formErrorModal.item_rab,
                  }"
                />
              </div>
            </div>
            <div class="form-group d-flex align-items-center">
              <label class="form-label">Nilai </label>
              <div class="w-100">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <div class="input-group-text">Rp</div>
                  </div>
                  <input
                    type="text"
                    class="form-control text-right"
                    :readonly="!isEditable"
                    id="inlineFormInputGroupUsername"
                    placeholder="Masukan Nilai"
                    @keyup="formChangePengeluaran('nilai', $event)"
                    :model-modifiers="{ number: true }"
                    v-money="configMoney"
                    v-model.lazy="formModalPengeluaran.nilai"
                    :class="{
                      'is-invalid': formErrorModal && formErrorModal.nilai,
                    }"
                  />
                </div>
                <div
                  v-if="formModalPengeluaran.sisa"
                  :style="{
                    fontSize: '13px',
                  }"
                  class="text-info mx-2"
                >
                  Sisa nilai item : {{ formModalPengeluaran.sisa }}
                </div>
                <div
                  class="form-error"
                  v-if="formErrorModal && formErrorModal.nilai"
                >
                  {{ formErrorModal.nilai }}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              @click="saveDetail"
              :disabled="!isEditable"
              class="btn btn-save"
            >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="main-wrapper">
    <NavbarDashboard></NavbarDashboard>
    <SidebarDashboard></SidebarDashboard>
    <div class="main-content">
      <form @submit.prevent="onSubmit">
        <section class="section">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <div class="">
              <h4 class="title">{{ labelPage }} Payment Order</h4>
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <router-link to="/data-payment">Payment Order</router-link>
                  </li>

                  <li class="breadcrumb-item active" aria-current="page">
                    {{ labelPage }} Payment Order
                  </li>
                </ol>
              </nav>
            </div>
            <div class="d-flex justify-content-end">
              <div v-if="labelPage != 'Tambah'">
                <router-link
                :to="{
                  name: 'DataPaymentOrderPrint',
                  params: {
                    kode: formData.id
                  }
                }"
                  type="button"
                  class="btn btn-blue mr-2"
                  style="font-size: 13px; min-width: 100px"
                >
                  Cetak
                </router-link>
              </div>
              <div
                v-if="
                  checkAccess('payment_order', 'cru_approval_payment_order') &&
                  labelPage != 'Tambah' &&
                  validated == 1
                "
              >
                <button
                  type="button"
                  @click="setApprove"
                  style="padding: 8px 12px; font-size: 13px; font-weight: 400"
                  v-if="
                    formData.status_pembayaran == 'menunggu persetujuan' &&
                    canApprove
                  "
                  :disabled="onApprove"
                  class="btn btn-edit mr-2"
                >
                  <span class="fas fa-spinner fa-spin" v-if="onApprove"></span>
                  Setujui Data
                </button>
              </div>
              <div
                class="button-edit"
                v-show="
                  validated == 1 &&
                  labelPage == 'Edit' &&
                  isEditable &&
                  formData.status_pembayaran == 'menunggu persetujuan' &&
                  checkAccess('payment_order', 'cru_payment_order')
                "
              >
                <button class="btn-add" type="button" @click="editable">
                  <img src="/img/icon-edit1.svg" alt="" style="width: 20px" />
                  Edit
                </button>
              </div>
              <div
                v-show="
                  (validated != 1 && labelPage == 'Edit') ||
                  labelPage == 'Tambah'
                "
              >
                <button
                  class="btn btn-save"
                  v-if="checkAccess('payment_order', 'cru_payment_order')"
                  :disabled="isSubmit"
                >
                  <span class="fas fa-spinner fa-spin" v-if="isSubmit"></span>
                  Simpan
                </button>
              </div>
            </div>
          </div>

          <!-- <iframe id="pdfFrame" style="height: 400px; width: 100%;"></iframe> -->

          <div class="card-table info-detail">
            <div class="row mt-2">
              <div class="col-6">
                <div class="title">{{ labelPage }} Payment Order</div>
                <div class="sub-title">
                  {{ labelPage }} informasi yang diperlukan
                </div>
              </div>
              <div
                class="col-6 d-flex justify-content-end align-items-center"
                v-if="formData.id"
              >
                <div class="form-group mb-0" v-if="formData.id">
                  <div
                    v-if="formData.status_pembayaran == 'paid'"
                    class="green-bedge px-3"
                    style="width: fit-content"
                  >
                    Paid
                  </div>
                  <div
                    v-else-if="
                      formData.status_pembayaran == 'menunggu persetujuan'
                    "
                    class="orange-bedge text-capitalize"
                  >
                    {{ formData.status_pembayaran }}
                  </div>
                  <div
                    v-else
                    class="red-bedge px-3 text-capitalize"
                    style="width: fit-content"
                  >
                    {{ formData.status_pembayaran }}
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row">
              <div class="col-12" v-if="labelPage == 'Edit'">
                <div class="form-group">
                  <label>Nomor Payment Order :</label>
                  <input
                    disabled="true"
                    type="text"
                    class="form-control"
                    placeholder="Masukkan Nomor Payment Order"
                    v-model="formData.id"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group">
                  <label>Kode Purchase Order :</label>
                  <Select2
                    v-model="formData.kode_po"
                    :options="optionKodePo"
                    :settings="{ templateResult: resultPO }"
                    placeholder="Pilih Kode Purchase Order"
                    @change="formChange('kode_po', $event)"
                    @select="formChange('kode_po', $event)"
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :class="{
                      'is-invalid': formError && formError.kode_po,
                    }"
                  />
                  <div class="form-error" v-if="formError && formError.kode_po">
                    {{ formError.kode_po }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Vendor/Supplier :</label>
                  <Select2
                    :disabled="
                      formData.kode_po ||
                      (validated == 1 && labelPage == 'Edit')
                        ? true
                        : false
                    "
                    v-model="formData.id_vendor"
                    :options="optionVendor"
                    placeholder="Pilih Vendor"
                    @change="formChange('id_vendor', $event)"
                    @select="formChange('id_vendor', $event)"
                    :class="{
                      'is-invalid': formError && formError.id_vendor,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_vendor"
                  >
                    {{ formError.id_vendor }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Hutang :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.beban_akun"
                    :options="optionAccountPayble"
                    :settings="{
                      templateResult: formatState,
                    }"
                    placeholder="Pilih Akun Hutang"
                    @change="formChange('beban_akun')"
                    @select="formChange('beban_akun')"
                    :class="{
                      'is-invalid': formError && formError.beban_akun,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.beban_akun"
                  >
                    {{ formError.beban_akun }}
                  </div>
                </div>
                <div class="form-group">
                  <label>Tanggal :</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.tanggal"
                    placeholder="Tanggal"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.tanggal,
                    }"
                    @update:modelValue="changeDate($event, 'tanggal')"
                  ></Datepicker>
                  <div class="form-error" v-if="formError && formError.tanggal">
                    {{ formError.tanggal }}
                  </div>
                </div>
                <!-- <div class="form-group">
                  <label>Potongan Hutang</label>
                  <input
                    disabled
                    type="text"
                    class="form-control text-right"
                    placeholder="Potongan Hutang"
                    :value="
                      formatMoney(
                        countPotongan(
                          formData.nilai,
                          formData.potongan_hutang,
                          'potongan'
                        )
                      )
                    "
                  />
                </div> -->
                <div class="form-group">
                  <label class="" for="inlineFormInputGroupUsername"
                    >Keterangan :</label
                  >
                  <textarea
                    type="text"
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    class="form-control"
                    v-model="formData.keterangan"
                    placeholder="Keterangan"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Project : </label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.kode_project"
                    :options="optionKodeProject"
                    placeholder="Pilih Kode Project"
                    @change="formChange('kode_project', $event)"
                    @select="formChange('kode_project', $event)"
                    :class="{
                      'is-invalid': formError && formError.kode_project,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.kode_project"
                  >
                    {{ formError.kode_project }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Departemen :</label>
                  <Select2
                    :disabled="
                      formData.kode_po ||
                      (validated == 1 && labelPage == 'Edit')
                        ? true
                        : false
                    "
                    v-model="formData.id_divisi"
                    :options="optionDepartement"
                    placeholder="Pilih Departemen"
                    @change="formChange('id_divisi')"
                    @select="formChange('id_divisi')"
                    :class="{
                      'is-invalid': formError && formError.id_divisi,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.id_divisi"
                  >
                    {{ formError.id_divisi }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Penanggung Jawab Keuangan :</label>
                  <Select2
                    v-model="formData.pic_keuangan"
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :options="optionEmployee"
                    :class="{
                      'is-invalid': formError && formError.pic_keuangan,
                    }"
                    placeholder="List Karyawan"
                    @change="formChange('pic_keuangan')"
                    @select="formChange('pic_keuangan')"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.pic_keuangan"
                  >
                    {{ formError.pic_keuangan }}
                  </div>
                </div>

                <div class="form-group">
                  <label>Jatuh Tempo :</label>
                  <Datepicker
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :autoApply="true"
                    :closeOnScroll="true"
                    v-model="formData.jatuh_tempo"
                    :minDate="formData.tanggal"
                    placeholder="Jatuh Tempo"
                    :format="format"
                    :enableTimePicker="false"
                    locale="id-ID"
                    selectText="Pilih"
                    cancelText="Batal"
                    :class="{
                      'is-invalid': formError && formError.jatuh_tempo,
                    }"
                    @update:modelValue="changeDate($event, 'jatuh_tempo')"
                  ></Datepicker>
                  <div
                    class="form-error"
                    v-if="formError && formError.jatuh_tempo"
                  >
                    {{ formError.jatuh_tempo }}
                  </div>
                </div>

                <div class="form-group">
                  <label>PPh :</label>
                  <div class="input-group">
                    <input
                      :disabled="validated == 1 && labelPage == 'Edit'"
                      type="text"
                      class="form-control text-right"
                      placeholder="Masukkan PPh"
                      v-model="formData.potongan_hutang"
                      @keyup="formChange('potongan_hutang')"
                      v-maska="'##############'"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text">%</div>
                    </div>
                  </div>
                </div>

                <div class="form-group">
                  <label>Akun Beban Pajak :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.beban_pajak"
                    :options="optionAccountTax"
                    :settings="{
                      templateResult: formatState,
                    }"
                    placeholder="Pilih Akun Beban Pajak"
                    @change="formChange('beban_pajak')"
                    @select="formChange('beban_pajak')"
                    :class="{
                      'is-invalid': formError && formError.beban_pajak,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.beban_pajak"
                  >
                    {{ formError.beban_pajak }}
                  </div>
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>File</label>
                  <fileUpload
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    :file="listFile"
                    @update="setFile"
                    @delete="setDelete"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="form-group">
                  <label>Rekening :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.rekening_id"
                    :options="rekeningByVendor(optionRekening)"
                    :settings="{
                      templateResult: formatStateRekening,
                      templateSelection: formatStateRekening,
                    }"
                    placeholder="Pilih Rekening Transfer"
                    class="select2-rekening"
                    @change="formChange('rekening_id', $event)"
                    @select="formChange('rekening_id', $event)"
                    :class="{
                      'is-invalid': formError && formError.rekening_id,
                    }"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.rekening_id"
                  >
                    {{ formError.rekening_id }}
                  </div>
                </div>
              </div>

              <div class="col-6" v-if="formData.rekening_id == '0'">
                <div class="form-group">
                  <label for="rekening_nama">Atas Nama:</label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    class="form-control"
                    id="rekening_nama"
                    @keyup="formChange('rekening_nama')"
                    v-model="formData.rekening_nama"
                    :class="{
                      'is-invalid': formError && formError.rekening_nama,
                    }"
                    placeholder="Masukkan Nomor Rekening"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.rekening_nama"
                  >
                    {{ formError.rekening_nama }}
                  </div>
                </div>
              </div>
              <div class="col-6" v-if="formData.rekening_id == '0'">
                <div class="form-group">
                  <label>Bank :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    class="editable"
                    v-model="formData.rekening_bank"
                    :options="optionBank"
                    placeholder="Bank"
                    :class="{
                      'is-invalid': formError && formError.rekening_bank,
                    }"
                    @change="formChange('rekening_bank', $event)"
                    @select="formChange('rekening_bank', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.rekening_bank"
                  >
                    {{ formError.rekening_bank }}
                  </div>
                </div>
              </div>
              <div class="col-6" v-if="formData.rekening_id == '0'">
                <div class="form-group">
                  <label for="rekening_nomor">Nomor Rekening:</label>
                  <input
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    type="text"
                    class="form-control"
                    v-maska="'#############################################'"
                    id="rekening_nomor"
                    @keyup="formChange('rekening_nomor')"
                    v-model="formData.rekening_nomor"
                    :class="{
                      'is-invalid': formError && formError.rekening_nomor,
                    }"
                    placeholder="Masukkan Nomor Rekening"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.rekening_nomor"
                  >
                    {{ formError.rekening_nomor }}
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-4">
                <div class="form-group">
                  <label>Tanda Tangan 1 :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.signing_person_first"
                    :options="optionEmployee"
                    placeholder="Pilih Karyawan"
                    :class="{
                      'is-invalid': formError && formError.signing_person_first,
                    }"
                    @change="formChange('signing_person_first', $event)"
                    @select="formChange('signing_person_first', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.signing_person_first"
                  >
                    {{ formError.signing_person_first }}
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-4">
                <div class="form-group">
                  <label>Tanda Tangan 2:</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.signing_person_second"
                    :options="optionEmployee"
                    placeholder="Pilih Karyawan"
                    :class="{
                      'is-invalid':
                        formError && formError.signing_person_second,
                    }"
                    @change="formChange('signing_person_second', $event)"
                    @select="formChange('signing_person_second', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.signing_person_second"
                  >
                    {{ formError.signing_person_second }}
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-4">
                <div class="form-group">
                  <label>Tanda Tangan Vendor :</label>
                  <Select2
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    v-model="formData.signing_person_vendor"
                    :options="optionTandaTanganVendor"
                    placeholder="Pilih Kontak Vendor"
                    class=""
                    :class="{
                      'is-invalid':
                        formError && formError.signing_person_vendor,
                    }"
                    @change="formChange('signing_person_vendor', $event)"
                    @select="formChange('signing_person_vendor', $event)"
                  />
                  <div
                    class="form-error"
                    v-if="formError && formError.signing_person_vendor"
                  >
                    {{ formError.signing_person_vendor }}
                  </div>
                </div>
              </div>
              <div class="col-6 col-sm-12">
                <div class="form-group">
                  <label>Terms & Condition</label>
                  <textarea
                    type="text"
                    :disabled="validated == 1 && labelPage == 'Edit'"
                    class="form-control"
                    v-model="formData.terms_condition"
                    placeholder="Masukkan Terms & Condition"
                    @keyup="formChange('terms_condition', $event)"
                    rows="5"
                    style="height: unset !important"
                  ></textarea>
                </div>
              </div>

              <div class="col-6" v-if="labelPage != 'Tambah'">
                <div class="form-group">
                  <label>Sisa Pembayaran</label>
                  <input
                    type="text"
                    :value="formatMoney(sisaPembayaran)"
                    disabled
                    class="form-control text-right"
                  />
                </div>
              </div>
            </div>

            <div
              v-if="
                labelPage == 'Tambah' &&
                (formData.kode_po || formData.kode_project)
              "
            >
              <div class="row mt-2">
                <div class="col-8">
                  <div class="title">Rincian Payment Order</div>
                  <div class="sub-title">
                    {{ labelPage }} informasi yang diperlukan
                  </div>
                </div>
                <div class="col-4 text-right">
                  <button
                    type="button"
                    @click="addDetail"
                    v-if="!formData.kode_po"
                    class="btn btn-add"
                  >
                    Tambah Data
                  </button>
                </div>
              </div>
              <hr />
              <div class="d-flex justify-content-end">
                <div class="card-total">
                  <div class="total-kredit d-flex flex-column mr-2">
                    <span>Potongan</span>
                    <span class="ml-auto"
                      >Rp
                      {{
                        formatMoney(
                          countPotongan(
                            formData.nilai,
                            formData.potongan_hutang,
                            "potongan"
                          )
                        )
                      }}</span
                    >
                  </div>
                  <div class="total-debit d-flex flex-column mr-0">
                    <span>Nilai</span>
                    <span class="ml-auto"
                      >Rp
                      {{
                        formatMoney(
                          countPotongan(
                            formData.nilai,
                            formData.potongan_hutang
                          )
                        )
                      }}</span
                    >
                  </div>
                </div>
              </div>

              <table
                class="table table-bordered table-striped mt-3"
                id="tablePO"
                v-if="formData.kode_po"
              >
                <thead>
                  <tr>
                    <th style="width: 20%">Kode Barang</th>
                    <th style="width: 20%">Nama</th>
                    <th style="width: 20%">Jumlah</th>
                    <th style="width: 20%">Item RAB</th>
                    <th style="width: 20%">Subtotal</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(value, index) in listDetail" :key="index">
                    <td>{{ value.kode_barang }}</td>
                    <td>{{ value.nama_barang }}</td>
                    <td>{{ value.qty + " " + value.nama_satuan }}</td>
                    <td>
                      <Select2
                        :disabled="formData.kode_project ? false : true"
                        v-model="listDetail[index].item_rab"
                        :options="optionRab"
                        class="itemRab"
                        :data-index="listDetail[index].item_rab"
                        :settings="{
                          templateResult: formatStateRab,
                        }"
                        @change="
                          rabChange($event, index, value.qty * value.nilai)
                        "
                        @select="
                          rabChange($event, index, value.qty * value.nilai)
                        "
                        placeholder="Pilih Item RAB"
                      />
                    </td>
                    <td class="text-right">
                      {{ formatMoney(value.qty * value.nilai) }}
                    </td>
                  </tr>
                </tbody>
              </table>

              <table
                class="table table-bordered table-striped mt-3"
                id="tablePO"
                v-else-if="formData.kode_project"
              >
                <thead>
                  <tr>
                    <th style="width: 15%">Akun</th>
                    <th style="width: 20%">Nama Item</th>
                    <th style="width: 20%">Nilai</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="listDetail.length == 0">
                    <td colspan="3" class="text-center">
                      Tidak ada data, silakan menambahkan terlebih dahulu
                    </td>
                  </tr>
                  <tr
                    v-for="(value, index) in listDetail"
                    :key="index"
                    @click="
                      editDetail(
                        index,
                        value.akun_beban,
                        value.nama,
                        value.nilai,
                        value.item_rab,
                        value.nama_item,
                        $event
                      )
                    "
                    :style="{ cursor: 'pointer' }"
                  >
                    <td>{{ value.nama }}</td>
                    <td>
                      {{ value.nama_item }}
                    </td>
                    <td
                      class="text-right position-relative"
                      style="padding-right: 40px !important"
                    >
                      {{ formatMoney(value.nilai * value.qty) }}
                      <img
                        src="/img/avatar/icon-delete.svg"
                        v-if="
                          (validated != 1 && labelPage == 'Edit') ||
                          labelPage == 'Tambah'
                        "
                        alt=""
                        class="btn-add-row"
                        style="
                          position: absolute;
                          right: 10px;
                          width: 20px;
                          top: 50%;
                          transform: translateY(-50%);
                          cursor: pointer;
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-else-if="formData.kode_po || formData.kode_project">
              <div>
                <div class="d-flex justify-content-between align-items-center">
                  <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link active"
                        id="pills-detail-tab"
                        data-toggle="pill"
                        href="#pills-detail"
                        role="tab"
                        aria-controls="pills-detail"
                        aria-selected="true"
                        >Rincian Hutang</a
                      >
                    </li>
                    <li class="nav-item" role="presentation">
                      <a
                        class="nav-link"
                        id="pills-anggota-tab"
                        data-toggle="pill"
                        href="#pills-anggota"
                        role="tab"
                        aria-controls="pills-anggota"
                        aria-selected="false"
                        >Pembayaran</a
                      >
                    </li>
                    <li
                      class="nav-item"
                      role="presentation"
                      v-if="listApproval.length > 0"
                    >
                      <a
                        class="nav-link"
                        id="pills-responsible-tab"
                        data-toggle="pill"
                        href="#pills-responsible"
                        role="tab"
                        aria-controls="pills-responsible"
                        aria-selected="false"
                        >Penanggung Jawab</a
                      >
                    </li>
                  </ul>
                  <button
                    type="button"
                    class="btn btn-download"
                    v-show="
                      formData.status_pembayaran != 'cancel' &&
                      formData.status_pembayaran != 'menunggu persetujuan' &&
                      formData.status_pembayaran != 'unpaid'
                    "
                    @click="flipJournal"
                  >
                    Jurnal Kembali
                  </button>
                </div>

                <hr style="margin-top: 0px" />
                <div class="tab-content" id="pills-tabContent">
                  <div
                    class="tab-pane fade show active"
                    id="pills-detail"
                    role="tabpanel"
                    aria-labelledby="pills-detail-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <div class="card-total">
                        <div class="total-kredit d-flex flex-column mr-2">
                          <span>Potongan</span>
                          <span class="ml-auto"
                            >Rp
                            {{
                              formatMoney(
                                countPotongan(
                                  formData.nilai,
                                  formData.potongan_hutang,
                                  "potongan"
                                )
                              )
                            }}</span
                          >
                        </div>
                        <div class="total-debit d-flex flex-column mr-0">
                          <span>Nilai</span>
                          <span class="ml-auto"
                            >Rp
                            {{
                              formatMoney(
                                countPotongan(
                                  formData.nilai,
                                  formData.potongan_hutang
                                )
                              )
                            }}</span
                          >
                        </div>
                      </div>
                    </div>

                    <div v-if="formError && formError.nilai">
                      <div
                        class="form-error"
                        :style="{ marginBottom: '5px', fontSize: '15px' }"
                      >
                        Masukan Terlebih dahulu rincian hutang
                      </div>
                    </div>

                    <div class="table-responsive mt-4">
                      <table
                        class="table table-bordered table-striped mt-3"
                        id="tablePO"
                        v-if="formData.kode_po"
                      >
                        <thead>
                          <tr>
                            <th style="width: 20%">Kode Barang</th>
                            <th style="width: 20%">Nama</th>
                            <th style="width: 20%">Jumlah</th>
                            <th style="width: 20%">Item RAB</th>
                            <th style="width: 20%">Subtotal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, index) in listDetail" :key="index">
                            <td>{{ value.kode_barang }}</td>
                            <td>{{ value.nama_barang }}</td>
                            <td>{{ value.qty + " " + value.nama_satuan }}</td>
                            <td>
                              <Select2
                                :disabled="formData.kode_project ? false : true"
                                v-model="listDetail[index].item_rab"
                                :options="optionRab"
                                class="itemRab"
                                :data-index="listDetail[index].item_rab"
                                :settings="{
                                  templateResult: formatStateRab,
                                }"
                                @change="
                                  rabChange(
                                    $event,
                                    index,
                                    value.qty * value.nilai
                                  )
                                "
                                @select="
                                  rabChange(
                                    $event,
                                    index,
                                    value.qty * value.nilai
                                  )
                                "
                                placeholder="Pilih Item RAB"
                              />
                            </td>
                            <td class="text-right">
                              {{ formatMoney(value.qty * value.nilai) }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table
                        class="table table-bordered table-striped mt-3"
                        id="tablePO"
                        v-else-if="formData.kode_project"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">Akun</th>
                            <th style="width: 20%">Nama Item</th>
                            <th style="width: 20%">Nilai</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-if="listDetail.length == 0">
                            <td colspan="3" class="text-center">
                              Tidak ada data, silakan menambahkan terlebih
                              dahulu
                            </td>
                          </tr>
                          <tr
                            v-for="(value, index) in listDetail"
                            :key="index"
                            @click="
                              editDetail(
                                index,
                                value.akun_beban,
                                value.nama,
                                value.nilai,
                                value.item_rab,
                                value.nama_item,
                                $event
                              )
                            "
                            :style="{ cursor: 'pointer' }"
                          >
                            <td>{{ value.nama }}</td>
                            <td>
                              {{ value.nama_item }}
                            </td>
                            <td
                              class="text-right position-relative"
                              style="padding-right: 40px !important"
                            >
                              {{ formatMoney(value.nilai * value.qty) }}
                              <img
                                src="/img/avatar/icon-delete.svg"
                                v-if="
                                  (validated != 1 && labelPage == 'Edit') ||
                                  labelPage == 'Tambah'
                                "
                                alt=""
                                class="btn-add-row"
                                style="
                                  position: absolute;
                                  right: 10px;
                                  width: 20px;
                                  top: 50%;
                                  transform: translateY(-50%);
                                  cursor: pointer;
                                "
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-anggota"
                    role="tabpanel"
                    aria-labelledby="pills-anggota-tab"
                  >
                    <div class="d-flex justify-content-end">
                      <div class="card-total">
                        <button
                          class="btn btn-add"
                          type="button"
                          @click="showModalPayment"
                          v-if="
                            labelPage != 'Tambah' &&
                            formData.status_pembayaran != 'paid' &&
                            formData.status_pembayaran !=
                              'menunggu persetujuan' &&
                            checkAccess(
                              'payment_order',
                              'cru_invoice_payment_order'
                            )
                          "
                        >
                          Pembayaran
                        </button>
                        <!-- <button class="btn btn-print-invoice" data-toggle="modal"
                                                    data-target="#modalPrintInvoice">Cetak Invoice</button> -->
                      </div>
                    </div>
                    <div class="table-responsive mt-3">
                      <table
                        class="table table-bordered table-striped table-hover"
                        id="tablePembayaranHutang"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">Nomor</th>
                            <th style="width: 20%">Tanggal</th>
                            <th style="width: 30%">Keterangan</th>
                            <th style="width: 15%">Nomor Referensi</th>
                            <th style="width: 15%">Jumlah</th>
                            <th style="width: 10%">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, index) in listPayment"
                            :key="index"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Klik untuk melihat detail"
                          >
                            <td>{{ value.no_transaksi }}</td>
                            <td>{{ formatDate(value.tanggal_pembayaran) }}</td>
                            <td>
                              {{ value.keterangan ? value.keterangan : "-" }}
                            </td>
                            <td>
                              {{
                                value.no_referensi ? value.no_referensi : "-"
                              }}
                            </td>
                            <td class="text-right">
                              {{ formatMoney(value.nilai) }}
                            </td>
                            <td>
                              <div
                                class="green-bedge"
                                v-if="value.tipe == 'pembayaran'"
                              >
                                Paid
                              </div>
                              <div class="orange-bedge" v-else>
                                Jurnal Kembali
                              </div>
                            </td>
                          </tr>
                          <tr v-if="listPayment.length === 0">
                            <td colspan="6" class="text-center">
                              Tidak ada data
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade"
                    id="pills-responsible"
                    role="tabpanel"
                    aria-labelledby="pills-responsible-tab"
                    v-if="listApproval.length > 0"
                  >
                    <div class="table-responsive mt-3">
                      <table
                        class="table table-bordered table-striped table-hover"
                        id="tableOpexRespons"
                      >
                        <thead>
                          <tr>
                            <th style="width: 15%">ID Karyawan</th>
                            <th style="width: 20%">Nama Karyawan</th>
                            <th style="width: 20%">Tanggal</th>
                            <th style="width: 10%">Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(value, index) in listApproval"
                            :key="index"
                          >
                            <td>{{ value.id_karyawan }}</td>
                            <td>{{ value.nama_karyawan }}</td>
                            <td>{{ formatDateTime(value.date) }}</td>
                            <td>
                              <div
                                class="green-bedge p-2"
                                v-if="value.status == 'disetujui'"
                              >
                                Disetujui
                              </div>
                              <div class="orange-bedge p-2" v-else>
                                Menunggu Persetujuan
                              </div>
                            </td>
                          </tr>
                          <tr v-if="listApproval.length === 0">
                            <td colspan="5" class="text-center">
                              Tidak ada data
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </form>
    </div>
  </div>
</template>

<script>
import SidebarDashboard from "../../../components/Sidebar.vue";
import NavbarDashboard from "../../../components/Navbar.vue";
import Select2 from "vue3-select2-component";
import Datepicker from "vue3-date-time-picker";
import { ref } from "vue";
import { jsPDF } from "jspdf";
import "jspdf-autotable";

import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";

import $ from "jquery";

import {
  checkFileOrImageFromLink,
  checkModuleAccess,
  checkRules,
  cksClient,
  showAlert,
} from "../../../helper";
import {
  get_ListDivisi,
  get_SatuanList,
  get_ListBank,
} from "../../../actions/master";
import { get_ListContactVendor, get_ListVendor } from "../../../actions/vendor";
import { get_BarangList } from "../../../actions/barang";
import { get_PurchaseOrderDetail } from "../../../actions/barang/purchaseOrder";
import moment from "moment";
import "moment/locale/id";

import { maska } from "maska";
import { VMoney } from "v-money";
import { get_AkunDefault, get_AkunList } from "../../../actions/akun_perkiraan";
import {
  get_HutangDetail,
  post_HutangSavePembayaran,
  post_HutangJurnalBack,
  get_HutangListPO,
  post_PaymentOrderSave,
} from "../../../actions/hutang";
import { get_ProjectList } from "../../../actions/project";
import { get_ProjectRabList } from "../../../actions/project/rab";
import { get_KaryawanList } from "../../../actions/karyawan";
import { get_CompanyRekeningList } from "../../../actions/company";
import { DATA } from "../../../constans/Url";
import fileUpload from "../components/fileUpload.vue";

// import TableLite from 'vue3-table-lite'
export default {
  name: "dataDepartement",
  components: {
    // FooterDashboard,
    SidebarDashboard,
    NavbarDashboard,
    Select2,
    Datepicker,
    fileUpload,
    // TableLite
  },

  directives: {
    money: VMoney,
    maska,
  },

  data() {
    return {
      checkAccess: checkModuleAccess,
      validated: 1,
      isEditable: true,
      optionVendor: [],
      optionDepartement: [],
      optionGoods: [],
      optionAccountPayble: [],
      optionAccountTax: [],
      optionKasBank: [],
      optionKodePo: [],
      optionKodeProject: [],
      optionEmployee: [],
      optionRab: [],
      optionBank: [],
      optionRekening: [
        {
          id: "0",
          text: "Tambah Baru",
          no: "-",
          type: "-",
          sub_level: 0,
          id_vendor: "",
        },
      ],
      id_company: cksClient().get("_account").id_company,
      accessDepartement: cksClient().get("_account").akses_departement,
      optionUnit: [],
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "",
        precision: 0,
        masked: false /* doesn't work with directive */,
      },
      //FORMDATA
      id: this.$route.params.id_hutang ? this.$route.params.id_hutang : "",
      labelPage: this.$route.params.id_hutang ? "Edit" : "Tambah",
      tanggal_po: "",
      isSubmit: false,
      listFile: [],
      fileDelete: [],
      optionTandaTangan: [{}],
      optionTandaTanganVendor: [],

      formData: {
        id: this.$route.params.id_hutang ? this.$route.params.id_hutang : "",
        kode_po: this.$route.query.kode_po ? this.$route.query.kode_po : "",
        kode_project: this.$route.query.kode_project
          ? this.$route.query.kode_project
          : "",
        id_company: cksClient().get("_account").id_company,
        id_vendor: "",
        id_divisi: "",
        beban_akun: "",
        nilai: "",
        jatuh_tempo: "",
        tanggal: "",
        id_jurnal: "",
        status: "aktif",
        status_pembayaran: "menunggu persetujuan",
        keterangan: "",
        pajak: "",
        potongan_hutang: 0,
        beban_pajak: "",
        nilai_pajak: "",
        pic_keuangan: "",
        rekening_id: "",
        rekening_nama: "",
        rekening_bank: "",
        rekening_nomor: "",
        signing_person_first: "",
        signing_person_second: "",
        signing_person_vendor: "",
        terms_condition: "",
      },
      formError: [],
      rules: {
        id_vendor: {
          required: true,
        },
        beban_akun: {
          required: true,
        },
        jatuh_tempo: {
          required: true,
        },
        tanggal: {
          required: true,
        },
        pic_keuangan: {
          required: true,
        },
        rekening_id: {
          required: false,
        },
        rekening_nama: {
          required: false,
        },
        rekening_bank: {
          required: false,
        },
        rekening_nomor: {
          required: false,
        },
        signing_person_first: {
          required: true,
        },
        signing_person_second: {
          required: false,
        },
        signing_person_vendor: {
          required: true,
        },
        terms_condition: {
          required: true,
        },
      },
      listDetail: [],
      listPayment: [],

      //pembayaran
      sisaPembayaran: 0,
      formPembayaran: {
        no_transaksi: "",
        id_hutang: this.$route.params.id_hutang
          ? this.$route.params.id_hutang
          : "",
        tanggal_pembayaran: "",
        no_referensi: "",
        status: "aktif",
        nilai: 0,
        id_akun: "",
        keterangan: "",
      },
      formErrorPembayaran: [],
      isPembayaran: false,
      rulesPembayaran: {
        nilai: {
          required: true,
          min: 1,
          max: 100000,
        },
        tanggal_pembayaran: {
          required: true,
        },
        id_akun: {
          required: true,
        },
      },

      //jurnal kembali
      isJurnalback: false,
      optionPembayaran: [],
      formJurnalKembali: {
        id_hutang: this.$route.params.id_hutang
          ? this.$route.params.id_hutang
          : "",
        id_pembayaran: "",
        akun_kas: "",
        tanggal: "",
        nilai: "",
        no_referensi: "",
        keterangan: "",
      },
      rulesJurnalKembali: {
        id_pembayaran: {
          required: true,
        },
        tanggal: {
          required: true,
        },
        nilai: {
          required: true,
          min: 1,
          max: 0,
        },
        no_referensi: {
          required: false,
        },
        keterangan: {
          required: false,
        },
      },
      formErrorJurnal: [],
      rabValue: "",
      //modal hutang
      formModalPengeluaran: {
        key: "",
        akun_beban: "",
        nilai: 0,
        nama: "",
        item_rab: "",
        qty: 0,
        nama_item: "",
        sisa: 0,
      },
      rulesModal: {
        nilai: {
          required: true,
        },
        akun_beban: {
          required: true,
        },
        item_rab: {
          required: true,
        },
      },
      formErrorModal: [],
      OptionAccountEstimated: [],
      onApprove: false,
      //dataPDF
      exportData: null,
      imageContainer: null,
      widthImage: {
        width: 0,
        height: 0,
      },

      //approval
      canApprove: false,
      listApproval: [],
    };
  },

  setup() {
    const date = ref(new Date());

    // In case of a range picker, you'll receive [Date, Date]
    const format = (date) => {
      const month = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];
      const day = date.getDate();
      const monthText = month[date.getMonth()];
      const year = date.getFullYear();

      return `${day} ${monthText} ${year}`;
    };
    return {
      date,
      format,
    };
  },

  mounted() {
    $(".tab-pane.fade").removeClass("active show");
    $("#pills-detail").addClass("active show");
    setTimeout(() => {
      $("#pills-detail-tab").tab("show");
    }, 600);
  },

  created() {
    if (this.labelPage == "Tambah") {
      this.getDefaultAkun();
    }
    this.getBarang();
    this.getDivisi();
    this.getVendor();
    this.getSatuan();
    this.getKaryawan();
    this.getBank();
    this.getRekening();
    this.getAkun("8,9,10");
    this.getAkun("8,9,14,15,19");
    this.getAkun("1,4,5,7");
    this.getAkun("9,10,13,14,15");
    this.getPOList();
    this.getProject();
    if (this.formData.kode_po) {
      this.getData();
    }
    if (this.formData.kode_project) {
      this.getItem();
    }
    if (this.formData.id) {
      this.getHutang();
    }
  },

  methods: {
    getBank() {
      get_ListBank((res) => {
        this.optionBank = res.list;
      });
    },
    getRekening() {
      get_CompanyRekeningList(
        {
          id_company: this.id_company,
        },
        (res) => {
          for (const key in res.list) {
            if (Object.hasOwnProperty.call(res.list, key)) {
              const element = res.list[key];
              this.optionRekening.push({
                id: element.id,
                text: element.atas_nama,
                no: element.nomor_rekening,
                type: element.bank,
                sub_level: 0,
                id_vendor: element.id_vendor,
              });

              if (element.id_vendor == this.formData.id_vendor) {
                this.formData.rekening_id = element.id;
              }
            }
          }
        }
      );
    },
    rekeningByVendor(list) {
      if (this.formData.id_vendor) {
        return list.filter(
          (e) => e.id == "0" || e.id_vendor == this.formData.id_vendor
        );
      } else {
        return list;
      }
    },
    getKaryawan() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_KaryawanList(
        {
          id_company: this.id_company,
          search: "aktif",
          id_divisi: id_divisi,
        },
        async (res) => {
          var { list } = res;
          this.optionEmployee = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              this.optionEmployee.push({
                id: list[key].id,
                text: list[key].nama,
              });
            }
          }
        }
      );
    },
    getDefaultAkun() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      var name_id = "akun_hutang_payment_order,akun_hutang_pph";
      get_AkunDefault(
        {
          id_company: this.id_company,
          name_id: name_id,
          id_divisi: id_divisi,
        },
        (res) => {
          var list = res.list;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              if (key == 0) {
                this.formData.beban_akun = element;
              } else if (key == 1) {
                this.formData.beban_pajak = element;
              }
            }
          }
        }
      );
    },
    getPOList() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_HutangListPO(
        {
          id_company: this.id_company,
          id_divisi: id_divisi,
          terms: true,
        },
        (res) => {
          if (this.formData.terms_condition == "") {
            this.formData.terms_condition = res.terms;
          }
          this.optionKodePo = res.list;
        }
      );
    },
    resultPO(val) {
      if (this.labelPage == "Tambah") {
        if (val.status_exist != "true") {
          return val.text;
        }
      } else {
        if (val.status_exist != "true" || this.formData.kode_po == val.id) {
          return val.text;
        }
      }
      return null;
    },
    getProject() {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_ProjectList(
        {
          id_company: this.id_company,
          id_divisi: id_divisi,
          search: "aktif",
        },
        (res) => {
          var list = res.list;
          for (const i in list) {
            if (Object.hasOwnProperty.call(list, i)) {
              const element = list[i];
              this.optionKodeProject.push({
                id: element.kode,
                text: element.nama,
                id_divisi: element.id_divisi,
              });
            }
          }
        }
      );
    },

    getItem() {
      get_ProjectRabList(this.formData.kode_project, (res) => {
        const { list } = res;
        // this.optionRAB.push({
        //   id: "other",
        //   text: "Nama item baru",
        // });
        this.optionRab = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            if (element.kategori == "vendor") {
              this.optionRab.push({
                id: element.id,
                text: element.nama,
                sub_level: element.sub_level,
                disabled: element.hasChild === 1 ? true : false,
                nilai: element.total_rab,
                sisa: element.sisa_nilai,
                max: element.sisa_nilai,
              });
            }
          }
        }

        if (this.optionRab.length == 0) {
          showAlert(this.$swal, {
          title: "PERHATIAN!",
            msg: `Tidak ada Item RAB dengan kategori Vendor`,
            showCancelButton: true,
            showConfirmButton: false,
          });
          setTimeout(() => {
            this.formData.kode_project = '';
          }, 100);
        }
      });
    },

    getVendorContact() {
      get_ListContactVendor(this.formData.id_vendor, (res) => {
        const { list } = res;
        this.optionTandaTanganVendor = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionTandaTanganVendor.push({
              id: element.id,
              text: `${element.nama} (${element.jabatan ?? "-"})`,
            });
          }
        }
      });
    },

    editable() {
      this.validated = 2;
    },
    setFile(e) {
      this.listFile = e;
    },
    setDelete(e) {
      if (e) {
        this.fileDelete.push(e);
      }
    },
    showModalPayment() {
      this.countSisaPembayaran();
      this.formPembayaran.no_transaksi = "";
      this.formPembayaran.tanggal_pembayaran = "";
      this.formPembayaran.no_referensi = "";
      this.formPembayaran.status = "";
      this.formPembayaran.nilai = "";
      this.formPembayaran.id_akun = "";
      this.formPembayaran.keterangan = "";
      $("#modalAddPayment").modal("show");
    },

    rabChange(val, index) {
      var nilaiAll = 0;
      for (const key in this.listDetail) {
        if (Object.hasOwnProperty.call(this.listDetail, key)) {
          const el = this.listDetail[key];
          if (el.item_rab && el.item_rab === val.id) {
            nilaiAll += el.qty * el.nilai;
          }
        }
      }

      this.listDetail[index].nama_item = val.text;

      if (nilaiAll > val.max) {
        setTimeout(() => {
          this.listDetail[index].item_rab = "";
        }, 100);
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: `Item RAB ${
            val.text
          } tidak bisa dipilih karena subtotal melebihi nilai sisa dari RAB yaitu ${this.formatMoney(
            val.max
          )}`,
          showCancelButton: true,
          showConfirmButton: false,
        });
        console.log(this.listDetail[index])
      }
    },

    searchObjectRab(id) {
      return this.listDetail.find((element) => element.id_rab == id);
    },

    formatStateRab(state) {
      if (!state.id) {
        return state.text;
      }

      // var check = this.searchObjectRab(state.id);
      // if (check && check.id_rab && state.id != this.rabValue) {
      //   return null;
      // }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = "";
      if (state.type && state.no) {
        $state = $(
          '<div class="' +
            padding +
            '">' +
            state.text +
            '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
            state.no +
            "<div>" +
            state.type +
            "</div></div></div>"
        );
      } else {
        $state = $('<div class="' + padding + '">' + state.text + "</div>");
      }
      return $state;
    },

    formatDate(string) {
      return moment(string).format("DD MMMM YYYY");
    },

    formatDateTime(string) {
      return string ? moment(string).format("DD MMMM YYYY HH:mm:ss") : "-";
    },

    getHutang() {
      get_HutangDetail(
        this.id,
        (res) => {
          const {
            data,
            invoice,
            pembayaran,
            is_editable,
            detail_hutang,
            approval_person,
            files,
          } = res;
          this.formData = data;
          this.exportData = res;

          this.listApproval = approval_person;
          if (data.status_pembayaran == "menunggu persetujuan") {
            if (
              this.checkAccess("payment_order", "cru_approval_payment_order")
            ) {
              if (data.kode_project != "" && approval_person.length > 0) {
                var dataPerson = approval_person.find(
                  (e) => e.id_karyawan == cksClient().get("_account").id
                );
                if (dataPerson && dataPerson.status == "menunggu persetujuan") {
                  this.canApprove = true;
                }
              } else {
                this.canApprove = true;
              }
            }
          }

          // this.generatePdf();
          if (data.kode_project) {
            this.getItem();
          }
          if (data.id_vendor) {
            this.getVendorContact();
          }
          this.umur = moment().diff(
            moment(data.created_at).format("YYYY-MM-DD"),
            "days"
          );
          this.formData.jatuh_tempo = data.jatuh_tempo
            ? moment(data.jatuh_tempo).format("YYYY-MM-DD")
            : "";
          this.formData.tanggal = data.tanggal
            ? moment(data.tanggal).format("YYYY-MM-DD")
            : "";
          delete this.formData.created_at;
          // this.formDetail = [];
          // for (const key in jurnal_data) {
          //   if (Object.hasOwnProperty.call(jurnal_data, key)) {
          //     var element = jurnal_data[key];
          //     delete element.no_jurnal;
          //     this.formDetail.push(element);
          //   }
          // }
          this.listDetail = detail_hutang;
          this.invoiceList = invoice;
          this.listPayment = pembayaran;
          this.optionPembayaran = [];
          for (const i in pembayaran) {
            if (Object.hasOwnProperty.call(pembayaran, i)) {
              const el = pembayaran[i];
              if (el.tipe == "pembayaran") {
                this.optionPembayaran.push({
                  id: el.no_transaksi,
                  text: el.no_transaksi,
                  id_akun: el.id_akun,
                  nilai: el.nilai_kembali
                    ? el.nilai - el.nilai_kembali
                    : el.nilai,
                });
              }
            }
          }
          this.isEditable = is_editable;

          this.listFile = [];
          for (const key in files) {
            if (Object.hasOwnProperty.call(files, key)) {
              const element = files[key];
              var type = checkFileOrImageFromLink(element.nama_file);
              this.listFile.push({
                id: element.session,
                preview:
                  type == "file"
                    ? "/img/icon_documents.png"
                    : DATA.HUTANG(element.nama_file),
                link_preview: DATA.HUTANG(element.nama_file),
                file: null,
                type: checkFileOrImageFromLink(element.nama_file),
              });
            }
          }

          this.countSisaPembayaran();
          this.countPajak();
        },
        () => {
          showAlert(this.$swal, {
            title: "WARNING!",
            msg: "Data payment order tidak ditemukan",
            showCancelButton: true,
            showConfirmButton: false,
            onCancel: () => {
              this.$router.push({
                name: "DataPaymentOrder",
              });
            },
          });
        }
      );
    },

    countSisaPembayaran() {
      var sisa = parseInt(
        this.countPotongan(this.formData.nilai, this.formData.potongan_hutang)
      );
      for (const key in this.listPayment) {
        if (Object.hasOwnProperty.call(this.listPayment, key)) {
          const element = this.listPayment[key];
          sisa -= element.nilai;
        }
      }
      this.sisaPembayaran = sisa;
      this.rulesPembayaran.nilai.max = this.formatMoney(sisa);
    },

    getAkun(tipe = "") {
      var id_divisi =
        this.accessDepartement && this.formData.id_divisi
          ? this.formData.id_divisi
          : "";
      get_AkunList(
        {
          id_company: this.id_company,
          tipe: tipe,
          id_divisi: id_divisi,
        },
        (res) => {
          var data = [];
          const { list } = res;
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              data.push({
                id: element.id,
                text: `${element.nama}`,
                no: element.id,
                type: element.tipe,
                sub_level: parseInt(element.sub_level),
                disabled:
                  parseInt(element.max_subLevel) === parseInt(element.sub_level)
                    ? false
                    : true,
              });
            }
          }
          if (tipe == "8,9,10") {
            this.optionAccountPayble = data;
          } else if (tipe == "8,9,14,15,19") {
            this.optionAccountTax = data;
          } else if (tipe == "1,4,5,7") {
            this.optionKasBank = data;
          } else if (tipe == "9,10,13,14,15") {
            this.OptionAccountEstimated = data;
          }
        }
      );
    },
    getData() {
      get_PurchaseOrderDetail(this.formData.kode_po, (res) => {
        var { data, detail, potongan } = res;
        delete data.created_at;
        delete data.updated_at;
        this.formData.id_vendor = data.id_vendor;
        this.formData.id_divisi = data.id_divisi;
        this.getVendorContact();
        this.listDetail = [];
        for (const key in this.optionRekening) {
          if (Object.hasOwnProperty.call(this.optionRekening, key)) {
            const element = this.optionRekening[key];
            if (element.id_vendor == data.id_vendor) {
              this.formData.rekening_id = element.id;
              break;
            }
          }
        }
        for (const i in detail) {
          if (Object.hasOwnProperty.call(detail, i)) {
            const element = detail[i];
            this.listDetail.push({
              kode_barang: element.kode_barang,
              nilai: element.harga_satuan,
              nama_barang: element.nama_barang,
              qty: element.qty,
              nama_satuan: element.nama_satuan,
              akun_beban:
                data.jenis == "pembelian"
                  ? element.akun_barang
                  : element.akun_sewa,
              item_rab: "",
              nama_item: "",
            });
          }
        }
        this.formData.potongan_hutang = potongan;
        var nilai = 0;
        for (const key in detail) {
          if (Object.hasOwnProperty.call(detail, key)) {
            const element = detail[key];
            nilai += element.qty * element.harga_satuan;
          }
        }
        this.formData.nilai = this.formatMoney(nilai);
      });
    },

    countPotongan($nilai, $potongan, type = "") {
      var _nilai = $nilai ? parseInt($nilai.toString().split(".").join("")) : 0;
      var _potongan = $potongan ? parseInt($potongan) : 0;
      var _fixPotongan = Math.ceil((_nilai * _potongan) / 100);
      if (!type) {
        return _nilai - _fixPotongan;
      } else if (type == "potongan") {
        return _fixPotongan;
      }
    },

    formatStateRekening(state) {
      // if (this.formData.id_vendor) {
      //   if (state.id && state.id_vendor != this.formData.id_vendor && state.id != '0') {
      //     return '';
      //   }
      // }
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    formatState(state) {
      if (!state.id) {
        return state.text;
      }
      var padding =
        state.sub_level == 2 ? "pl-3" : state.sub_level == 1 ? "pl-2" : "";
      var $state = $(
        '<div class="' +
          padding +
          '">' +
          state.text +
          '<div class="noEstimated" style="font-size: 12px;color: #898989; display:flex; justify-content: space-between;">' +
          state.no +
          "<div>" +
          state.type +
          "</div></div></div>"
      );
      return $state;
    },

    //master
    countHarga(harga_satuan, qty) {
      var harga = parseInt(harga_satuan.toString().split(".").join(""))
        ? parseInt(harga_satuan.toString().split(".").join(""))
        : 0;
      return this.formatMoney(harga * qty);
    },
    formatMoney(a) {
      if (!a) {
        return 0;
      }
      return a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getDivisi() {
      get_ListDivisi({ id_company: this.id_company }, (res) => {
        const { list } = res;
        this.optionDepartement = [];
        for (const key in list) {
          if (Object.hasOwnProperty.call(list, key)) {
            const element = list[key];
            this.optionDepartement.push(element);
          }
        }
      });
    },
    getSatuan() {
      get_SatuanList(
        (res) => {
          var { list } = res;
          this.optionUnit = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionUnit.push({
                id: element.id,
                text: element.nama,
              });
            }
          }
        },
        (e) => {
          console.log(e);
        }
      );
    },
    async getBarang() {
      await get_BarangList(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          var { list } = res;
          this.optionGoods = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionGoods.push({
                text: element.nama,
                id: element.kode,
                satuan: element.satuan,
                nama_satuan: element.nama_satuan,
                nama_kategori: element.nama_kategori,
                pagu: element.harga_pagu,
              });
            }
          }
        },
        () => {
          this.list = [];
        }
      );
    },
    getVendor() {
      get_ListVendor(
        {
          id_company: this.id_company,
          search: "aktif",
        },
        (res) => {
          const { list } = res;
          this.optionVendor = [];
          for (const key in list) {
            if (Object.hasOwnProperty.call(list, key)) {
              const element = list[key];
              this.optionVendor.push({
                id: element.id,
                text: element.nama,
                potongan: element.npwp ? 2 : 4,
                rekening_id: element.rekening_id,
              });
            }
          }
        }
      );
    },
    tooltip() {
      $('[data-toggle="tooltip"]').tooltip();
    },
    changeDate(data, key) {
      if (key == "tanggal_pembayaran") {
        this.formPembayaran[key] = moment(data).format("YYYY-MM-DD");
      } else {
        this.formData[key] = moment(data).format("YYYY-MM-DD");
        this.formChange(key);
      }
    },
    async formChange(key, val) {
      if (this.rules[key]) {
        this.rules[key].changed = true;
      }
      if (key == "pajak") {
        this.countPajak();
      }
      if (key == "kode_po") {
        // var item = this.optionVendor.find((element) => element.id == val.id_vendor)
        this.formData.id_vendor = val.id_vendor;
        this.formData.id_divisi = val.id_divisi;
        this.getVendorContact();
        // this.formData.nilai_pajak = (item.)
        this.getData();
      }

      if (key == "id_vendor") {
        this.getVendorContact();
      }

      if (key == "rekening_id") {
        if (val.id == "0") {
          this.rules.rekening_nama.required = true;
          this.rules.rekening_bank.required = true;
          this.rules.rekening_nomor.required = true;
        } else {
          this.rules.rekening_nama.required = false;
          this.rules.rekening_bank.required = false;
          this.rules.rekening_nomor.required = false;
        }
      }

      if (key == "id_vendor") {
        this.formData.potongan_hutang = val.potongan;
        for (const key in this.optionRekening) {
          if (Object.hasOwnProperty.call(this.optionRekening, key)) {
            const element = this.optionRekening[key];
            if (element.id_vendor == val.id) {
              this.formData.rekening_id = element.id;
              break;
            }
          }
        }
      }

      if (key == "kode_project") {
        this.formData.id_divisi = val.id_divisi;
        this.getItem();
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
    },

    //MODAL HUTANG
    async formChangePengeluaran(key, val) {
      if (this.rulesModal[key]) {
        this.rulesModal[key].changed = true;
      }
      if (key == "akun_beban") {
        this.formModalPengeluaran.nama = val.text;
      }

      if (key == "item_rab" && val.id != "other") {
        this.rulesModal.nilai.max = this.formatMoney(val.max);
        if (
          !this.formModalPengeluaran.nilai ||
          this.formModalPengeluaran.nilai != "0" ||
          this.formModalPengeluaran.nilai != 0 ||
          this.formModalPengeluaran.nilai != `'0'`
        ) {
          this.formModalPengeluaran.nilai = this.formatMoney(val.nilai);
        }
        this.formModalPengeluaran.sisa = this.formatMoney(val.sisa);
      } else if (key == "item_rab") {
        delete this.rulesModal.nilai.max;
        this.formModalPengeluaran.sisa = 0;
      }
      var check = await checkRules(this.rulesModal, this.formModalPengeluaran);
      this.formErrorModal = check.error;
      return check.success;
    },
    async saveDetail() {
      if (this.formModalPengeluaran.item_rab != "other") {
        this.formModalPengeluaran.nama_item = $(
          "#selectRab option:selected"
        ).text();
      }
      for (const key in this.rulesModal) {
        if (Object.hasOwnProperty.call(this.rulesModal, key)) {
          this.rulesModal[key].changed = true;
        }
      }
      var check = await checkRules(this.rulesModal, this.formModalPengeluaran);
      this.formErrorModal = check.error;

      if (
        ((this.formModalPengeluaran.nilai &&
          this.formModalPengeluaran.nama_item) ||
          this.formModalPengeluaran.nilai) &&
        check.success
      ) {
        if (this.formModalPengeluaran.key !== "") {
          this.listDetail[this.formModalPengeluaran.key] = {
            akun_beban: this.formModalPengeluaran.akun_beban,
            nama: this.formModalPengeluaran.nama,
            item_rab: this.formModalPengeluaran.item_rab,
            nama_item: this.formModalPengeluaran.nama_item,
            nilai: parseInt(this.formModalPengeluaran.nilai.replace(/\./g, "")),
            qty: 1,
          };
        } else {
          this.listDetail.push({
            akun_beban: this.formModalPengeluaran.akun_beban,
            nama: this.formModalPengeluaran.nama,
            item_rab: this.formModalPengeluaran.item_rab,
            nama_item: this.formModalPengeluaran.nama_item,
            nilai: parseInt(this.formModalPengeluaran.nilai.replace(/\./g, "")),
            qty: 1,
          });
        }
        this.countDetail();
        $("#modalPengeluaran").modal("hide");
      }
    },
    editDetail(key, id, nama, nilai, id_rab, nama_item, ev) {
      if (ev.target && ev.target.className === "btn-add-row") {
        this.deleteDetail(key);
      } else if (
        (this.validated != 1 && this.labelPage == "Edit") ||
        this.labelPage == "Tambah"
      ) {
        this.formModalPengeluaran.key = key;
        this.formModalPengeluaran.akun_beban = id;
        this.formModalPengeluaran.nilai = nilai;
        this.formModalPengeluaran.nama = nama;
        this.formModalPengeluaran.qty = 1;
        this.formModalPengeluaran.item_rab = id_rab;
        this.formModalPengeluaran.nama_item = nama_item;
        $("#modalPengeluaran").modal("show");
      }
    },
    deleteDetail(key) {
      this.listDetail.splice(key, 1);
      this.countDetail();
    },
    countDetail() {
      var nilai = 0;
      for (const key in this.listDetail) {
        if (Object.hasOwnProperty.call(this.listDetail, key)) {
          const element = this.listDetail[key];
          nilai += parseInt(element.nilai * element.qty);
        }
      }
      this.formData.nilai = nilai;
      this.formChange();
    },
    addDetail() {
      console.log(this.OptionAccountEstimated);
      // if (ev.id) {
      this.onModal = true;
      this.formModalPengeluaran.key = "";
      this.formModalPengeluaran.akun_beban = "";
      this.formModalPengeluaran.nilai = 0;
      this.formModalPengeluaran.nama = "";
      this.formModalPengeluaran.item_rab = "";
      this.formModalPengeluaran.qty = 1;
      this.formModalPengeluaran.nama_item = "";
      this.formModalPengeluaran.sisa = 0;
      $("#modalPengeluaran").modal("show");
      // }
    },

    countPajak() {
      var data = 0;
      for (const key in this.listDetail) {
        if (Object.hasOwnProperty.call(this.listDetail, key)) {
          const element = this.listDetail[key];
          data += element.qty * element.nilai;
        }
      }
      var pajak = parseInt(this.formData.pajak)
        ? parseInt(this.formData.pajak)
        : 0;
      var nilai = Math.round((data * pajak) / 100);
      this.formData.nilai = nilai + data;
      this.formData.nilai_pajak = nilai;
      this.rulesPembayaran.nilai.max = nilai + data;
    },

    async onSubmit() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      var checkRab = true;
      if (this.formData.kode_po && this.formData.kode_project) {
        for (const i in this.listDetail) {
          if (Object.hasOwnProperty.call(this.listDetail, i)) {
            const element = this.listDetail[i];
            if (!element.item_rab) {
              checkRab = false;
              break;
            }
          }
        }
      }
      var check = await checkRules(this.rules, this.formData);
      this.formError = check.error;
      if (check.success && this.listDetail.length > 0 && checkRab) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: this.formData.id
            ? "Apakah anda yakin, Tindakan ini akan menyimpan semua perubahan yang anda lakukan?"
            : "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postData();
          },
        });
      } else if (check.success) {
        var msg =
          !checkRab && this.formData.kode_po && this.formData.kode_project
            ? "Silakan pilih item rab terlebih dahulu"
            : (this.formData.kode_po || this.formData.kode_project) &&
              this.listDetail.length == 0
            ? "Silakan masukan rincian payment order terlebih dahulu sebelum melanjutkan"
            : "Silakan pilih Kode Purchase Order dan atau Project terlebih dahulu";
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: msg,
          showCancelButton: true,
          showConfirmButton: false,
        });
      }
    },

    postData() {
      var dataFormFix = { ...this.formData };
      dataFormFix.nilai = this.formData.nilai;
      dataFormFix.listDetail = this.listDetail;

      var dataForm = new FormData();
      for (const key in dataFormFix) {
        if (Object.hasOwnProperty.call(dataFormFix, key)) {
          const element = dataFormFix[key];
          if (key == "listDetail") {
            element.forEach((obj, index) => {
              Object.entries(obj).forEach(([i, value]) => {
                dataForm.append(`${key}[${index}][${i}]`, value);
              });
            });
          } else {
            dataForm.append(key, element);
          }
        }
      }
      // this.formData.files = [];
      for (const key in this.listFile) {
        if (Object.hasOwnProperty.call(this.listFile, key)) {
          const element = this.listFile[key];
          if (element.file) {
            dataForm.append(`files[]`, element.file);
          }
        }
      }
      for (const key in this.fileDelete) {
        if (Object.hasOwnProperty.call(this.fileDelete, key)) {
          const element = this.fileDelete[key];
          dataForm.append(`deletefile[]`, element);
        }
      }

      this.isSubmit = true;
      post_PaymentOrderSave(
        dataForm,
        (res) => {
          this.isSubmit = false;
          var msg =
            res.response_code === 201
              ? "Data payment order berhasil dicatat"
              : "Data payment order berhasil diperbarui";
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
            onSubmit: () => {
              if (localStorage.getItem("_supply") && this.formData.kode_po) {
                this.$router.push({
                  name: "DetailPOSupply",
                  params: {
                    kode: this.formData.kode_po,
                  },
                });
              } else if (
                localStorage.getItem("_inventory") &&
                this.formData.kode_po
              ) {
                this.$router.push({
                  name: "DetailPO",
                  params: {
                    kode: this.formData.kode_po,
                  },
                });
              } else {
                this.$router.push({
                  name: "DataPaymentOrder",
                });
              }
            },
          });
        },
        () => {
          this.isSubmit = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    //PEMBAYARAN
    postPembayaran() {
      var dataForm = this.formPembayaran;
      dataForm.nilai = this.formPembayaran.nilai.split(".").join("");
      this.isPembayaran = true;
      post_HutangSavePembayaran(
        dataForm,
        (res) => {
          this.isPembayaran = false;
          var msg =
            res.response_code === 201
              ? "Data pembayaran Payment Order berhasil dicatat"
              : "Data pembayaran Payment Order berhasil diperbarui";
          this.getHutang();

          $("#modalAddPayment").modal("hide");
          setTimeout(() => {
            this.isPembayaran = false;
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: msg,
            });
          }, 500);
        },
        () => {
          this.isPembayaran = false;
          setTimeout(() => {
            this.isPembayaran = false;
            showAlert(this.$swal, {
              title: "ERROR!",
              msg: "Terjadi kesalahan, silakan ulangi kembali",
              showCancelButton: true,
              showConfirmButton: false,
            });
          }, 500);
        }
      );
    },

    //pembayaran
    changeDatePembayaran(data, key) {
      this.formPembayaran[key] = moment(data).format("YYYY-MM-DD");
      this.formChangePembayaran(key);
    },

    async formChangePembayaran(val) {
      if (Object.hasOwnProperty.call(this.rulesPembayaran, val)) {
        this.rulesPembayaran[val].changed = true;
      }
      var check = await checkRules(this.rulesPembayaran, this.formPembayaran);
      this.formErrorPembayaran = check.error;
    },

    async savePembayaran() {
      for (const key in this.rules) {
        if (Object.hasOwnProperty.call(this.rules, key)) {
          this.rules[key].changed = true;
        }
      }
      this.formPembayaran.nilai = this.formatMoney(this.formPembayaran.nilai);
      var check = await checkRules(this.rulesPembayaran, this.formPembayaran);
      this.formError = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postPembayaran();
          },
        });
      }
    },

    //setujui payment order
    setApprove() {
      showAlert(this.$swal, {
        title: "PERHATIAN!",
        msg: "Apakah anda yakin, akan menyetujui payment order ini?",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Lanjutkan",
        onSubmit: () => {
          this.postApprove();
        },
      });
    },

    postApprove() {
      var dataForm = this.formData;
      var jumlahApproval = this.listApproval.length;
      var approvalSetujui = this.listApproval.filter(
        (e) => e.status == "disetujui"
      ).length;

      if (jumlahApproval == 0) {
        dataForm.status_pembayaran = "unpaid";
      } else if (jumlahApproval - approvalSetujui == 1) {
        dataForm.status_pembayaran = "unpaid";
        dataForm.karyawan_approval = cksClient().get("_account").id;
      } else {
        dataForm.karyawan_approval = cksClient().get("_account").id;
      }
      dataForm.nilai = this.formData.nilai;
      dataForm.listDetail = this.listDetail;
      this.onApprove = true;
      this.canApprove = false;
      post_PaymentOrderSave(
        dataForm,
        () => {
          this.onApprove = false;
          this.validated = 1;
          var msg = "Data payment order berhasil disetujui";
          this.getHutang();
          showAlert(this.$swal, {
            title: "BERHASIL!",
            msg: msg,
          });
        },
        () => {
          this.onApprove = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },

    //jurnal kembali
    async formChangeJurnal(val, value = "") {
      if (Object.hasOwnProperty.call(this.rulesJurnalKembali, val)) {
        this.rulesJurnalKembali[val].changed = true;
      }

      if (val == "id_pembayaran") {
        this.rulesJurnalKembali.nilai.max = this.formatMoney(value.nilai);
        this.formJurnalKembali.akun_kas = value.id_akun;
      }

      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
    },
    changeDateJurnal(data, key) {
      this.formJurnalKembali[key] = moment(data).format("YYYY-MM-DD");
      this.formChangeJurnal(key);
    },
    flipJournal() {
      this.formJurnalKembali.id_pembayaran = "";
      this.formJurnalKembali.tanggal = "";
      this.formJurnalKembali.nilai = "";
      this.formJurnalKembali.keterangan = "";
      this.formJurnalKembali.no_referensi = "";
      $("#modalJurnalBack").modal("show");
      // this.$router.push({
      //   name: "FlipJournalPayble",
      //   params: {
      //     label: "Hutang",
      //   },
      // });
    },
    postJurnalBack() {
      this.isJurnalback = true;
      post_HutangJurnalBack(
        this.formJurnalKembali,
        () => {
          $("#modalJurnalBack").modal("hide");
          this.isJurnalback = false;
          this.getHutang();
          setTimeout(() => {
            showAlert(this.$swal, {
              title: "BERHASIL!",
              msg: "Jurnal kembali berhasil dicatat",
            });
          }, 500);
        },
        () => {
          this.isJurnalback = false;
          showAlert(this.$swal, {
            title: "ERROR!",
            msg: "Terjadi kesalahan, silakan ulangi kembali",
            showCancelButton: true,
            showConfirmButton: false,
          });
        }
      );
    },
    async saveJurnalBack() {
      for (const key in this.rulesJurnalKembali) {
        if (Object.hasOwnProperty.call(this.rulesJurnalKembali, key)) {
          this.rulesJurnalKembali[key].changed = true;
        }
      }
      var check = await checkRules(
        this.rulesJurnalKembali,
        this.formJurnalKembali
      );
      this.formErrorJurnal = check.error;
      if (check.success) {
        showAlert(this.$swal, {
          title: "PERHATIAN!",
          msg: "Apakah anda yakin, semua data yang telah anda isikan akan tersimpan?",
          showCancelButton: true,
          cancelButtonText: "Batal",
          confirmButtonText: "Ya, Lanjutkan",
          onSubmit: () => {
            this.postJurnalBack();
          },
        });
      }
    },

    getDataUri(url) {
      this.imageContainer = new Image();
      return new Promise((resolve) => {
        this.imageContainer.setAttribute("crossOrigin", "anonymous"); //getting images from external domain
        // console.log(image.width, image.height);
        this.imageContainer.onload = function () {
          var canvas = document.createElement("canvas");
          canvas.width = this.naturalWidth;
          canvas.height = this.naturalHeight;
          // this.getScaleImage(this.naturalWidth, this.naturalHeight);
          //next three lines for white background in case png has a transparent background
          var ctx = canvas.getContext("2d");
          ctx.fillStyle = "#6BACCC"; /// set white fill style
          ctx.fillRect(0, 0, canvas.width, canvas.height);

          canvas.getContext("2d").drawImage(this, 0, 0);

          resolve(canvas.toDataURL("image/jpeg"));
        };

        this.imageContainer.src = url;
      });
    },

    getScaleImage(width, height) {
      var MAX_WIDTH = 40;
      var MAX_HEIGHT = 25;

      // Change the resizing logic
      if (width > height) {
        if (width > MAX_WIDTH) {
          height = height * (MAX_WIDTH / width);
          width = MAX_WIDTH;
        }
        if (height > MAX_HEIGHT) {
          width = width * (MAX_HEIGHT / height);
          height = MAX_HEIGHT;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width = width * (MAX_HEIGHT / height);
          height = MAX_HEIGHT;
        }

        if (width > MAX_WIDTH) {
          height = height * (MAX_WIDTH / width);
          width = MAX_WIDTH;
        }
      }

      return {
        w: width,
        h: height,
      };
    },

    async generatePdf() {
      const doc = new jsPDF("p", "mm", "letter");
      doc.setFontSize(13);
      const { data, data_company } = this.exportData;
      //start header
      //start add image header
      var logo = await this.getDataUri(DATA.COMPANY(data_company.company_logo));
      const widthImage = this.getScaleImage(
        this.imageContainer.width,
        this.imageContainer.height
      );

      var imageX =
        widthImage.w > 40 / 2 && widthImage.w < 40
          ? 40 - widthImage.w
          : widthImage.w > 40
          ? 8
          : widthImage.w == 40
          ? 8
          : 40 / 2;
      var imageY =
        widthImage.h > 25 / 2 && widthImage.h < 25
          ? 25 - widthImage.h
          : widthImage.h > 25
          ? 5
          : widthImage.h == 25
          ? 5
          : 25 / 2;
      console.log(widthImage.w, widthImage.h, imageX, imageY);
      doc.addImage(
        logo,
        "PNG",
        imageX < 8 ? 8 : imageX,
        imageY < 5 ? 5 : imageY,
        widthImage.w,
        widthImage.h
      );
      //end image header

      // var widthHeader = 216 - 51 - 5
      // doc.setFillColor("68caf8");
      // doc.rect(51, 5, widthHeader, 25, "F")
      var xOffset = 0;
      //add text company
      doc.setFontSize(12);
      xOffset =
        doc.internal.pageSize.width / 2 -
        doc.getStringUnitWidth(`${data_company.nama}`) / 2;
      doc.text(xOffset, 14, `${data_company.nama}`);
      //add alamat
      doc.setFontSize(10);
      xOffset = doc.internal.pageSize.width / 2 + 20;
      doc.text(xOffset, 19, `${data_company.alamat}`, {
        maxWidth: 130,
        align: "center",
      });
      //add phone number
      xOffset = doc.internal.pageSize.width / 2 + 20;
      doc.text(
        xOffset,
        25,
        `No Telp: ${data_company.no_telp}    Email: ${data_company.email}`,
        {
          maxWidth: 130,
          align: "center",
        }
      );

      // doc.setDrawColor(0, 0, 0);
      doc.line(5, 28, doc.internal.pageSize.width - 10, 28);
      doc.setLineWidth(0.8);
      doc.line(5, 29, doc.internal.pageSize.width - 10, 29);

      //end header

      //start body
      var yOffsetBody = 35;
      doc.setFontSize(9);
      // doc.rect(5, yOffsetBody, 35, 10, "F");
      doc.text("PO NUMBER", 5, yOffsetBody, {
        maxWidth: 35,
      });

      doc.text(" : ", 35.5, yOffsetBody, {
        maxWidth: 2,
      });

      doc.setFillColor("e82107");
      var textDimensions = doc.getTextDimensions(data.id);
      // doc.setFontYype()
      doc.rect(
        38,
        yOffsetBody - 3.5,
        textDimensions.w + 4,
        textDimensions.h + 2,
        "F"
      );
      doc.setFontType("bold");
      doc.text(`  ${data.id}`, 38, yOffsetBody);

      setTimeout(() => {
        // var iframe = document.getElementById("pdfFrame");
        // iframe.src = doc.output("bloburi", {
        //   filename: `Neraca ${this.namaReport}.pdf`,
        // });
      }, 500);
    },
  },
};
</script>

<style scoped src="../../../assets/css/style.css"></style>

<style scoped src="../../../assets/css/custom.css"></style>

<style scoped>
.card .card-title {
  font-size: 18px;
  color: #000;
  font-weight: 500;
}

.info-detail .title {
  font-size: 18px;
  font-weight: 500;
}

.info-detail .sub-title {
  color: #c6c6c6;
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
}
</style>